import PropTypes from 'prop-types';

const Scene = ({ children }) => (
  <main>
    <div className="hero min-h-screen bg-white dark:bg-gray-900
      text-black dark:text-white">
      <div className="container mx-auto flex justify-between">
        {children}
      </div>
    </div>
  </main>
);

Scene.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Scene;
