import { useEffect, useState } from 'react';
import sentinel from '../GSR/sentinel';

const UserAgentInfo = () => {
  const [userAgentInfo, setUserAgentInfo] = useState({
    os: '',
    browser: '',
  });

  useEffect(() => {
    const { os, browser } = sentinel.getPlatformInfo();
    setUserAgentInfo({ os, browser });
  }, []);

  return (
    <p className="text-slate-400 px-4 py-1 float-left text-sm">
      User Agent Information
      <br />
      <strong>Operating System:</strong> {userAgentInfo.os} {' '}
      <strong>Browser:</strong> {userAgentInfo.browser}
    </p>
  );
};

export default UserAgentInfo;
