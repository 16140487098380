import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import UserAgentInfo from '../UserAgentInfo';
import GSR from '../GSR';

const Browser = ({ children }) => (
  <div className="w-3/4 bg-white dark:bg-gray-900">
    <div className="mockup-browser border bg-base-300 h-full
    bg-white dark:bg-gray-900">
      <div className="mockup-browser-toolbar">
        <div className="input dark:text-black">https://bankingdemo.com</div>
        <div className="flex justify-end">
          <NavLink to="/" className="btn btn-outline text-black
            dark:text-white">
            Restart
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M15.312 11.424a5.5 5.5 0 0 1-9.201
                2.466l-.312-.311h2.433a.75.75 0 0 0 0-1.5H3.989a.75.75
                0 0 0-.75.75v4.242a.75.75 0 0 0 1.5 0v-2.43l.31.31a7 7 0
                0 0 11.712-3.138.75.75 0 0 0-1.449-.39Zm1.23-3.723a.75.75
                0 0 0 .219-.53V2.929a.75.75 0 0 0-1.5 0V5.36l-.31-.31A7
                7 0 0 0 3.239 8.188a.75.75 0 1 0 1.448.389A5.5 5.5 0 0 1
                13.89 6.11l.311.31h-2.432a.75.75 0 0 0 0 1.5h4.243a.75.75
                0 0 0 .53-.219Z"
                clipRule="evenodd"
              />
            </svg>
          </NavLink>
        </div>
      </div>
      <div
        className="flex justify-center items-center bg-base-200 h-full
        relative dark:bg-gray-800 text-black dark:text-white"
      >
        {children}
      </div>
      <div className="bottom-bar dark:bg-gray-900 text-black dark:text-white">
        <UserAgentInfo />
        <GSR />
      </div>
    </div>
  </div>
);

Browser.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Browser;
