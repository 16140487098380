import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Browser from '../../components/Browser';
import Menu from '../../components/Menu';

const Step3 = () => {
  const location = useLocation();
  const newRecipientToggle = location.state?.newRecipientToggle;
  const newRecipientName = location.state?.newRecipientName;
  const newRecipientAccount = location.state?.newRecipientAccount;
  const existingRecipientAccount = location.state?.existingRecipientAccount;
  const amount = location.state?.amount;
  const sourceAccount = location.state?.sourceAccount;

  return (
    <>
      <Menu activeStep={2} />
      <Browser>
        <div className="card w-2/4 bg-base-100 shadow-xl mb-16
          bg-white dark:bg-gray-900 text-black dark:text-white">
          <div className="card-body">
            <h2 className="card-title">Confirm Transaction Details</h2>
            <div className="overflow-x-auto">
              <table className="table">
                <tbody>
                  <tr>
                    <td>From</td>
                    <td className="text-right">{sourceAccount}</td>
                  </tr>
                  <tr>
                    <td>To</td>
                    <td className="text-right">
                      {newRecipientToggle
                        ? `${newRecipientName} - ${newRecipientAccount}`
                        : existingRecipientAccount}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td className="text-right">{amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card-actions justify-end">
              <NavLink to="/step4">
                <button className="btn btn-primary">
                  Confirm
                </button>
              </NavLink>
              <NavLink to="/step2">
                <button className="btn">Cancel</button>
              </NavLink>
            </div>
          </div>
        </div>
      </Browser>
    </>
  );
};

Step3.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      amount: PropTypes.string,
      sourceAccount: PropTypes.string,
    }),
  }),
};

export default Step3;
