import PropTypes from 'prop-types';
import { Step, Stepper } from '@cimpress/react-components';
import DarkModeToggler from '../DarkModeToggler';

import LogoLight from '../../images/graboxy-light.svg';
import LogoDark from '../../images/graboxy-dark.svg';

const Menu = ({ activeStep }) => (
  <div className="w-1/4 flex justify-start items-start flex-col">
    <div className="mb-5 pb-5">
      <div className="hidden dark:block">
        <img src={LogoDark} />
      </div>
      <div className="dark:hidden">
        <img src={LogoLight} />
      </div>
      <p>
        Remote Access Detection
        {' '}
        <span className="demo">Demo</span>
      </p>
    </div>
    <div className="mb-5 pb-5">
      <DarkModeToggler />
    </div>
    <div style={{ height: '350px' }}>
      <Stepper activeStep={activeStep} vertical>
        <Step>
          <div>Start</div>
        </Step>
        <Step>
          <div>Transaction Details</div>
        </Step>
        <Step>
          <div>Review Transaction</div>
        </Step>
        <Step>
          <div>Fraud Detection</div>
        </Step>
        <Step>
          <div>Session Completed</div>
        </Step>
      </Stepper>
    </div>
    <p className="text-slate-400 mt-auto">
      © 2024, Cursor Insight
    </p>
  </div>
);

Menu.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default Menu;
